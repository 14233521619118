import React, { useEffect, useState} from 'react'
import LazyLoad from 'react-lazyload';
import teamchallange from "../finalist_photos/finalsdteamchallange-min.webp"
import sciencecenter from "../finalist_photos/sciencecenter-min.webp"
import finalswalking from "../finalist_photos/finalswalking-min.webp"
import finalseatingdinner from "../finalist_photos/finalseatingdinner-min.webp"
import AOS from "aos";
import '../App.css'
import './About.css'
import Avatar from '@mui/material/Avatar';
import BoardofAdvisors from './BoardofAdvisors';
import CelebrationIcon from '@mui/icons-material/Celebration';
import rudgerlogo from "../unilogos/rudger.png"
import { styled } from '@mui/material/styles';



function News() {



  const [uID, setUID] = useState(false); 
  const [iroUid, setIroUid] = useState('');

  const [successOpenMUI, setSuccessOpenMUI] = useState(false);
  const [failOpenMUI, setFailOpenMUI] = useState(false);

  const [open, setOpen] = useState(false);


  const trueOrFalseDisableButton = JSON.parse(localStorage.getItem('trueOrFalseDisableButton'));
  const [enterEmailButtonDisable, setEnterEmailButtonDisable] = useState(trueOrFalseDisableButton);

  useEffect(() => {
    AOS.init({ duration: 2000 }); //might need to be AOS
  }, []);

  const gotostripe = () => {
    window.location.href = "https://register.internationalresearcholympiad.org/b/eVadSR8SDexrfhmdQS"
    
  }


    
  const handleCloseMUI = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessOpenMUI(false);
    setFailOpenMUI(false);
    setUID(false)
  };

  const handleClose = () => {
    setOpen(false);
  };



  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width:  34,
    height: 40,
  }));

  const SmallAvatarr = styled(Avatar)(({ theme }) => ({
    width:  40,
    height: 40,
  }));


  return (
   
      <div className='container'>

     

        <div className='row rowpad1' data-aos="fade-up">

            <div className='col' style={{paddingTop: "50px"}}>
                <p className="maintitle bigblueonthewing">What is the IRO?</p>
            </div>

              <p className="lowertitle">
              The International Research Olympiad (IRO), is a competition representing 45+ countries' top youth researchers. The IRO is an olympiad-style competition dedicated solely to scientific research, and aims to promote essential skills for science research among young minds. The 2025 International Research Olympiad competition is gearing up, and will test skills related to critical thinking, analytical reasoning, science communication, and more.
              </p>

              <div className='row' data-aos="fade-up">
                <div className="col">
                    <div className="cool-image-guy-holding-blocks-od">
                  
                      <LazyLoad height={200} offset={100} once>
                        <img style={{width: "400px"}} src={sciencecenter} className="cool-image-guy-holding-blocks"/>
                    </LazyLoad>

                    </div>
                </div>
                <div className="col">
                    <div className="cool-image-guy-holding-blocks-od">
                  
                      <LazyLoad height={200} offset={100} once>
                        <img style={{width: "400px"}} src={finalswalking} className="cool-image-guy-holding-blocks"/>
                    </LazyLoad>

                    </div>
                </div>
                <div className="col">
                    <div className="cool-image-guy-holding-blocks-od">
                  
                      <LazyLoad height={200} offset={100} once>
                        <img style={{width: "400px"}} src={finalseatingdinner} className="cool-image-guy-holding-blocks"/>
                    </LazyLoad>

                    </div>
                </div>
              </div>


              <div className='col' style={{paddingTop: "50px"}}>
                <p className="secondarytitle">Our Mission</p>
              </div>

              <p className="lowertitle">
              Designed to inspire and challenge students aged 13-18 around the globe, the IRO aims to cultivate many essential qualities of the research process. Unlike traditional science olympiads that delve into specific subjects, the IRO encourages students to build skills that will be useful in any research discipline. Through this, participants foster a deeper understanding of the scientific process and build overall creativity, critical thinking, and analysis skills, forming a foundation for later success.<br/><br/>

              </p>
              <p className="lowertitle">
              Every year, numerous ambitious students from grades 6–12 engage in research through local universities and summer programs. Recognizing a gap in the development of authentic research skills among these students, the IRO was conceived to address this vital need. It serves as a platform to empower ambitious students, providing them with the skills necessary to make meaningful contributions to the scientific community.<br/><br/>
              </p>



              <BoardofAdvisors />


           


              <div className='col' style={{paddingTop: "50px"}}>
                <p className="secondarytitle">The 2024 IRO Examinations (Concluded)</p>
              </div>

              <p className="lowertitle">
              The 2024 IRO garnered overwhelming interest with over 3,000 students interested, and 1,000 taking the Opens level exam. Based on critique and discussion with the Board of Advisors, the IRO tests student skills across written tests, research proposal submissions, research project presentations, and more. There are three phases of the contest: opens, semis, and finals. At the IRO Awards Ceremony in Cambridge, MA, held in the Harvard Square, the medalists and special award recipients were declared.<br/><br/>
              </p>

              <p className="lowertitle">
              Administered by a team of researchers guided by an experienced Board of Advisors, the IRO has ambitious plans for the future, including the implementation of country-based examinations, organizing camps, and hosting finals at the prestigious Harvard University in Cambridge, MA. The 2024 edition was a significant milestone, heavily publicized to introduce and reward students who are developing essential research skills.
              </p>

              <p className="lowertitle">
              We invite you to embark on this exciting journey with us as we shape the future of science research. We encourage you to check out the <a style={{color: "black"}} href="https://internationalresearcholympiad.com/winners">2024 winners</a> of the competition, and the highlight reel!
              </p>
      



            <div className='col' style={{paddingTop: "50px"}}>
                <p className="maintitle bigblueonthewing">About Us</p>
            </div>

            <div className='row align-items-center'>
                <div class="clearfix">
                  <LazyLoad height={200} offset={100} once>
                      <img style={{width: "400px"}} src={teamchallange} className="col-md-6 image-css-invert-none float-md-end mb-3 ms-md-3"/>
                  </LazyLoad>

                  <p className="lowertitle">
                  The International Research Olympiad (IRO) is dedicated to honing analytical and critical thinking skills for student researchers—essential in scientific exploration. The IRO fosters a culture of rigorous research and skill-building, not just memorizing formulae. The practical applications of knowledge and big-picture thinking are tested in the 2024 IRO examinations, allowing students to walk away with a deeper passion for scientific inquiry.
                  </p>
                </div>
            </div>

              <div className='col' style={{paddingTop: "50px"}}>
                <p className="maintitle bigblueonthewing">Recent Updates</p>
            </div>

            <div style={{paddingTop: "50px"}}>
                <p className="secondarytitle">Registration</p>
            </div>


              <p className="lowertitle">
              The International Research Olympiad 2025 registration will open in January 2025.
              </p>
              <p className="lowertitle">
              If you are interested in participating in the 2025 IRO, please enter your email <a style={{color: "black"}} href="https://internationalresearcholympiad.com/">here</a> for registration details and instructions upon release. Practice exams and other materials from past years can be found on our website, and additional resources will be released during the 2025 registration through email and our website.
              </p>

              

                <div style={{paddingTop: "50px"}}>
                <p className="secondarytitle">New Updates</p>

                <p className="lowertitle">
                <b>6/6/24</b> - Winners of the IRO 2024 were announced in Cambridge, MA!<br/><br/>
                <b>5/5/24</b> - Results for the 2024 IRO Semifinals Exam Released!<br/><br/>
                <b>3/28/24</b> - Results for the 2024 IRO Opens Exam Released!<br/><br/>
                <b>2/28/24</b> - IRO Finals will be held at Harvard University!<br/><br/>
                <b>2/3/24</b> - Registration for the 2024 IRO has opened!<br/><br/>
                <b>2/1/24</b> - Our 2024 Board of Advisors has been finalized!<br/><br/>
                <b>1/25/24</b> - Our 2024 Student Board of Advisors has been publicly released!<br/><br/>
                <b>1/1/24</b> - Our 2024 online community has been publicly released! <br/>

                </p>
            </div>

        </div>


      </div>
    
  )
}

export default News
