
// PracticeExams
import { useEffect } from 'react';

import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CelebrationIcon from '@mui/icons-material/Celebration';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';



function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function PracticeExams() {

  const gotostripe = () => {
    window.location.href = "https://register.internationalresearcholympiad.org/b/eVadSR8SDexrfhmdQS"
    
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="container">
      <div className='col' style={{paddingTop: "50px"}}>
          <p className="maintitle bigblueonthewing">Mock Open Exams</p>
      </div>
      <Box sx={{ width: '100%' }}>
        
        <Stack direction="row" style={{overflowX:'auto'}} spacing={1}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
             <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
               <Tab label="Mock Q. 1" {...a11yProps(0)} />
               <Tab label="Mock Q. 2" {...a11yProps(1)} />
               <Tab label="Mock Q. 3" {...a11yProps(2)} />
               <Tab label="Mock Q. 4" {...a11yProps(3)} />
               <Tab label="Mock Q. 5" {...a11yProps(4)} />
               <Tab label="Mock Q. 6" {...a11yProps(5)} />
               <Tab label="Mock Q. 7" {...a11yProps(6)} />
             </Tabs>
           </Box>

         </Stack>
         <CustomTabPanel value={value} index={0}>

            <Typography variant="caption" display="block" gutterBottom>
              Note: Answer Key Below
            </Typography>
                <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1O5RTcscuG7XEwEh3VnVJPZfR2yFz31oH/preview#view=FitH"  allow="autoplay"></iframe>
                <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1E_nUSSXAHmgaOo90ql760c8lwERTpmxR/preview#view=FitH"  allow="autoplay"></iframe>
         </CustomTabPanel>
         <CustomTabPanel value={value} index={1}>
              <Typography variant="caption" display="block" gutterBottom>
              Note: Answer Key Below
            </Typography>
              <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1PUnIKqZ-ntGyNvbjNavhX6Xbx9yGdzZD/preview#view=FitH"  allow="autoplay"></iframe>
              <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1_5N8HeZBDKjgMlR5ZuSuLjM24rz4HFs2/preview#view=FitH"  allow="autoplay"></iframe>
         </CustomTabPanel>
         <CustomTabPanel value={value} index={2}>
         <Typography variant="caption" display="block" gutterBottom>
         Note: Answer Key Below
       </Typography>
         <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1nzV2n86G9-MzYOQKdxIR8eKVmWJnQYq_/preview#view=FitH"  allow="autoplay"></iframe>
         <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1X0BAU_G3-uogEWrWjpWvj14hsomwbdn9/preview#view=FitH"  allow="autoplay"></iframe>
         </CustomTabPanel>
         <CustomTabPanel value={value} index={3}>
         <Typography variant="caption" display="block" gutterBottom>
         Note: Answer Key Below
       </Typography>
         <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1J6X7K8KmPj45LsfL74PiQ0F6ZP92D6Ih/preview#view=FitH"  allow="autoplay"></iframe>
         <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1VD3z-ughgEFh9BwTxCSqxiI1BpDS9pPH/preview#view=FitH"  allow="autoplay"></iframe>
         </CustomTabPanel>
         <CustomTabPanel value={value} index={4}>
            <Typography variant="caption" display="block" gutterBottom>
            Note: Answer Key Below
          </Typography>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1PQgtcDTpSuTyJ_IITVmvf4BIF_30-0mF/preview#view=FitH"  allow="autoplay"></iframe>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1YSbTOfyeQ3r_Ds5uyEBX5iwqqtZX18Fv/preview#view=FitH"  allow="autoplay"></iframe>
         </CustomTabPanel>
         <CustomTabPanel value={value} index={5}>
            <Typography variant="caption" display="block" gutterBottom>
            Note: Answer Key Below
          </Typography>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1Xj9CRB_9x5qR4qxQx27z_C4pFRQik2FT/preview#view=FitH"  allow="autoplay"></iframe>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1IYqKbh645xIvGz3d9IaBk5rI4KoYkQ60/preview#view=FitH"  allow="autoplay"></iframe>
         </CustomTabPanel>
         <CustomTabPanel value={value} index={6}>
            <Typography variant="caption" display="block" gutterBottom>
            Note: Answer Key Below
          </Typography>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1rSzZcH-SYlprmTERVKSfdNss9TyzgT9j/preview#view=FitH"  allow="autoplay"></iframe>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1uInpAu9a3ogZMfaHVTLBPRKUfD5fkYen/preview#view=FitH"  allow="autoplay"></iframe>
         </CustomTabPanel>
       </Box>

    </div>
  );
}
