import React from 'react';

function ClubPresentationSlides() {
  return (
    <div className="container">
    <div className='row rowpad1'>
        {/* <div className='col' style={{paddingTop: "50px"}} data-aos="fade-up">
          <p className="maintitle" style={{textAlign: 'center', marginBottom: '50px'}}>The Curriculum</p>
        </div> */}
      </div>
      <div className='row psuhonbttomsmallscreen'>
        <div className='col' data-aos="fade-up">
        
        <div className="container" >
  
          {/* <img src={logo} className="id-logo-css-iro-dhfgasdfjhgasdjfhagsdkfhasdfgsjdhf" alt=""/> */}

          <h1 className="bigblueonthewing">Research Club Presentation Slides:</h1>
          <br className="br"/>
          <h4>Sets of presentation slides that you can use in your club meetings</h4>
        
        </div>
       </div>
       
        </div>
        

        <div className='row rowpad1'>
          <div class="card text-center">
            <div class="card-header">
            Meeting 1 - Introduction 
            </div>
            <div class="card-body">
              <iframe 
                src="https://docs.google.com/presentation/d/13CJoPjzu8AhNzn_KUu8Rgx3zeZAFQn1FQM0slwGzhJs/preview" 
                style={{width: '100%', height: '100vh'}}
                allow="autoplay"
                frameBorder="0"
              />
            </div>
            <div class="card-footer text-body-secondary">
              Click <a style={{color: "bigblueonthewing"}} href="https://docs.google.com/presentation/d/13CJoPjzu8AhNzn_KUu8Rgx3zeZAFQn1FQM0slwGzhJs/">Here</a> to access the slides.
            </div>
          </div>

        </div>

        <div className='row rowpad1'>
          <div class="card text-center">
            <div class="card-header">
            Meeting 2 - Literature Review
            </div>
            <div class="card-body">
              <iframe 
                src="https://docs.google.com/presentation/d/1VkE7qPT3-OuwyPklIMa7IlTJaAD0rYgW6CWSNFI1Rqo/preview" 
                style={{width: '100%', height: '100vh'}}
                allow="autoplay"
                frameBorder="0"
              />
            </div>
            <div class="card-footer text-body-secondary">
              Click <a style={{color: "bigblueonthewing"}} href="https://docs.google.com/presentation/d/1VkE7qPT3-OuwyPklIMa7IlTJaAD0rYgW6CWSNFI1Rqo/">Here</a> to access the slides.
            </div>
          </div>

        </div>


</div>
  );
}

export default ClubPresentationSlides;
