
// PracticeExams
import { useEffect } from 'react';

import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CelebrationIcon from '@mui/icons-material/Celebration';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';



function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function PracticeExamsTwo() {

  const gotostripe = () => {
    window.location.href = "https://register.internationalresearcholympiad.org/b/eVadSR8SDexrfhmdQS"
    
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="container">
      <div className='col' style={{paddingTop: "50px"}}>
          <p className="maintitle bigblueonthewing">Mock Semi Exams</p>
      </div>
      <Box sx={{ width: '100%' }}>
        
        <Stack direction="row" style={{overflowX:'auto'}} spacing={1}>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Mock Q. 1" {...a11yProps(0)} />
              <Tab label="Mock Q. 2" {...a11yProps(1)} />
              <Tab label="Mock Q. 3" {...a11yProps(2)} />
              <Tab label="Mock Q. 4" {...a11yProps(3)} />
              <Tab label="Mock Question Solutions" {...a11yProps(4)} />
            </Tabs>
          </Box>

        </Stack>
        <CustomTabPanel value={value} index={0}>
        
      <Typography variant="caption" display="block" gutterBottom>
      </Typography>
          <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1xTXiG1CWnyW0CCbNRjO5b4e33bHgP88w/preview#view=FitH"  allow="autoplay"></iframe>
          <div className="row rowpad1">
             <p className="lowertitle"><b>Mock Oral Question:</b> <br/>
             Explain the purpose of a superconductor, specifically going into detail about LK-99 and the properties of its electronic structure in making it a superconductor. Discuss if LK-99 holds properties to be used in industrial and medical applications of superconductors and suggest further investigations in the STEM field for LK-99.
             </p>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
        <Typography variant="caption" display="block" gutterBottom>
      </Typography>
        <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1VKjNHhKWmg4Ao12z06nyUnwPFMvnMPi2/preview#view=FitH"  allow="autoplay"></iframe>
        <div className="row rowpad1">
             <p className="lowertitle"><b>Mock Oral Question:</b> <br/>
             Discuss the fundamental principles of machine learning and delve into the capabilities of machine learning libraries such as AutoKeras. Provide potential advancements in this technology through future research avenues and its application in optimizing inefficient systems within specific industries.
             </p>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
        <Typography variant="caption" display="block" gutterBottom>
      </Typography>
        <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1UsD29UaWKBC0W3hu3HFW8SeX7ZPu0BYo/preview#view=FitH"  allow="autoplay"></iframe>
        <div className="row rowpad1">
             <p className="lowertitle"><b>Mock Oral Question:</b> <br/>
             Evaluate the need for STREAM and its development compared to other measures of impairment, discussing potential uses in conjunction with invasive brain-computer interfaces like Neuralink. Detail an opinion on the use of STREAM and any ideas for further developing this research.
             </p>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
        <Typography variant="caption" display="block" gutterBottom>
      </Typography>
        <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1Tu9__DQH29WPeeiXV-LB7-_5mIA4IQ4v/preview#view=FitH"  allow="autoplay"></iframe>
        <div className="row rowpad1">
             <p className="lowertitle"><b>Mock Oral Question:</b> <br/>
             Analyze the process of creating artificial enzymes through multiple active sites by enzyme-metal nanoparticle hybrids. Discuss the potential applications and transferability of these novel enzymes in different industrial sectors and their implications for achieving more environmentally friendly and sustainable chemical processes at scale.
             </p>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <Typography variant="caption" display="block" gutterBottom>
        </Typography>
          <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1SVn_CzhD2b3LHtau3nckmVYYYAdXmCze/preview#view=FitH"  allow="autoplay"></iframe>
        </CustomTabPanel>
    
      </Box>

      <div className="row rowpad1" style={{alignItems: "center", justifyContent: "center"}}>

        
         

        </div>
    </div>
  );
}
