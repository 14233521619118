import React, { useEffect } from 'react'
import Skeleton from '@mui/material/Skeleton';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CelebrationIcon from '@mui/icons-material/Celebration';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import TextField from '@mui/material/TextField';
import AOS from "aos";
import { Link } from 'react-router-dom';
import '../App.css'
import logo from '../irofullpng.webp'; 


function Reviewandvideos() {

  const gotostripe = () => {
    window.location.href = "https://register.internationalresearcholympiad.org/b/eVadSR8SDexrfhmdQS"
    
  }

  return (
    <div>
      <div className="container">
        <div className='row rowpad1'>
            {/* <div className='col' style={{paddingTop: "50px"}} data-aos="fade-up">
              <p className="maintitle" style={{textAlign: 'center', marginBottom: '50px'}}>The Curriculum</p>
            </div> */}
          </div>
          <div className='row psuhonbttomsmallscreen'>
            <div className='col' data-aos="fade-up">
            
            <div className="container" >
      
              {/* <img src={logo} className="id-logo-css-iro-dhfgasdfjhgasdjfhagsdkfhasdfgsjdhf" alt=""/> */}

              <h1 className="bigblueonthewing">IRO Official Curriculum Guide For Open Exam:</h1>
              <br className="br"/>
              <p>The IRO Open Exam is a 60-minute exam that will test students' understanding of analytical skills and critical thinking skills through multiple-choice questions. After the open exams, the top 10% of students will then advance to the semifinal round which will have more complex and advanced questions. The top 20 scores from the semifinal round will then have the opportunity to compete in the final round of IRO.</p>
              <p>The 2024 International Research Olympiad offers this comprehensive guide to enhance your exam preparation. It outlines the exam's covered topics and provides a wealth of resources to support your olympiad readiness. Explore recommended websites for accessing research papers, along with valuable links guiding you on how to effectively read and comprehend research papers, facilitating a deeper understanding of the research process. Get ready to excel in the upcoming Olympiad with this curated study aid.</p>

              <h5>Table of Contents:</h5>
              <br className="br"/>
   
              <a href="#format" className="bigblueonthewing" >Open Exam Format:</a>
              <br className="br"/>
              <a href="#topics" className="bigblueonthewing">Topics in the IRO:</a>
              <br className="br"/>
              <a href="#read" className="bigblueonthewing">How to Read a Research Paper:</a>
              <br className="br"/>
              <a href="#litReview" className="bigblueonthewing">How to Conduct a Literature Review:</a>
              <br className="br"/>
              <a href="#practice" className="bigblueonthewing">How to Practice:</a>
              <br className="br"/>
              <br className="br"/>

              <h5 id='format'>Open Exam Format:</h5>
              <p>The open exam format for the International Research Olympiad adheres to the familiar multiple-choice structure found in most olympiads, with a distinctive twist. There will be a total of 5 papers, each containing 8 questions, testing competitors' analytical and critical thinking skills. However, here's where it gets interesting - participants can choose to attempt only 4 out of the 5 papers.</p>
              <p>While the exam allocates 60 minutes for completion, there won't be extra points for tackling all 5 papers. Only the first 4 papers you answer will be considered in the evaluation. This means you have a little over 20 minutes for each paper, offering flexibility in managing your time effectively. Given the diverse range of topics covered in the papers, you can strategically allocate time based on your proficiency in each subject.</p>
              <p>In total, competitors will collectively answer 32 questions, adding an extra layer of choice and strategy to the Olympiad experience. Good luck!</p>

              <h5 id='topics'>Topics in the IRO:</h5>
              <p>The IRO will cover scientific and mathematical research. We are currently focusing on the following fields:</p>
              <ul>
                <li>Computer Science</li>
                <li>Engineering</li>
                <li>Medicine</li>
                <li>Neuroscience</li>
                <li>Physics</li>
                <li>Mathematics</li>
                <li>Artificial Intelligence</li>
                <li>Biology</li>
              </ul>

              <h5 id='read'>How to Read a Research Paper</h5>
              <p>Research papers, as you know by now, are the keys to understanding groundbreaking discoveries, fascinating theories, and the latest advancements in a field. Unfortunately, they can also appear to be dense, jargon-filled behemoths of academia. Some would liken it to Mount Everest: daunting and intimidating, but I would say that you just need a Sherpa — this guide — to reach the summit. The International Research Olympiad is built upon the idea that reading papers and discerning scientific knowledge is crucial — this will provide a framework for reading various research papers, including tools and technologies that can make it even more effective.</p>
              <p className='bold'>Breaking down the paper</p>
              <ul>
                <li>Abstract: The abstract succinctly encapsulates the essence of the entire research paper. It typically comprises:</li>
                <li>A problem statement: Briefly highlighting the research question or hypothesis being addressed.</li>
                <li>Methodology: Summarizing the employed research methods and data collection techniques.</li>
                <li>Key findings: Outlining the major results and significant contributions of the study.</li>
                <li>Conclusions: Offering concise yet impactful deductions drawn from the research findings.</li>
              </ul>
              <p>The abstract serves as a crucial entry point for readers, offering a condensed overview and enabling them to determine the paper's relevance to their interests.</p>

              <p className='bold'>Introduction</p>
              <p>The introduction provides context and sets the stage for the research. It typically includes:</p>
              <ul>
                <li>Background: Establishing the broader scholarly context of the research topic.</li>
                <li>Literature review: Summarizing existing knowledge and identifying research gaps addressed by the present study.</li>
                <li>Research question or hypothesis: Clearly stating the specific questions or propositions the research aims to answer or test.</li>
                <li>Significance of the study: Explaining the potential contributions of the research to the field.</li>
              </ul>

              <p className='bold'>Methods</p>
              <ul>
                <li>Study design: Specifying the type of research conducted (e.g., experimental, observational).</li>
                <li>Participants or samples: Describing the characteristics and selection criteria of the study population. (If applicable)</li>
                <li>Data collection: Explaining the instruments and techniques used to gather data.</li>
                <li>Data analysis: Detailing the statistical methods employed to analyze and interpret the collected data. (This can vary paper to paper)</li>
              </ul>

              <p className='bold'>Results</p>
              <ul>
                <li>Descriptive statistics: Summarizing the characteristics of the data through measures of central tendency and dispersion.</li>
                <li>Inferential statistics: Reporting the outcomes of statistical tests conducted to test the research question or hypothesis.</li>
                <li>Presentation of data: Employing tables, figures, and graphs to visualize the findings effectively.</li>
              </ul>

              <p className='bold'>Discussion</p>
              <p>The discussion section interprets the results within the context of the existing literature and broader scholarly framework. It typically includes:</p>
              <ul>
                <li>Explanation of findings: Linking the results to the research question or hypothesis.</li>
                <li>Comparison with existing research: Highlighting similarities and differences with previous studies.</li>
                <li>Limitations: Acknowledging potential weaknesses or constraints of the research.</li>
                <li>Implications: Discussing the broader significance and potential applications of the findings.</li>
              </ul>

              <p className='bold'>Conclusion</p>
              <ul>
                <li>Restatement of the research question or hypothesis: Briefly reminding the reader of the study's main objective.</li>
                <li>Summary of key findings: Highlighting the major results and their significance.</li>
                <li>Future directions: Proposing potential avenues for future research based on the study's findings.</li>
              </ul>

              <p className='bold'>The Reading Approach</p>
              <p>Now that you are relatively well acquainted with the structure, we can proceed with the Three-Pass Method.</p>

              <p className='italics'>Pass 1: Establishing Context</p>
              <p>This initial pass aims to provide a broad overview of the paper’s key elements. Begin by scrutinizing the abstract, title, and introduction. This trio forms the cornerstone of the research, encapsulating the central query investigated, the methodology employed, and the principal findings garnered. Additionally, scan through the section headings to gain a preliminary understanding of the paper’s organization and flow. By the end of this initial pass, you should be able to succinctly articulate the research question and identify the core findings.</p>
              <p>For the IRO, it is recommended that you understand the context of the paper before looking at the questions. This is because they might not necessarily provide the context required to understand them. However, it is recommended you continue on to Pass 2 only after reading the questions. Now, you know exactly what to look for.</p>

              <p className='italics'>Pass 2: Deciphering the Methodology and Results</p>
              <p>The second pass delves deeper into the technical heart of the research. Focus your attention on the methods and results sections. In the methods section, meticulously examine the research design, participant selection process, data collection procedures, and employed analytical techniques. This meticulous examination builds a foundation for critically evaluating the validity and reliability of the study. Subsequently, in the results section, pay close attention to the presented data, both in its raw form and through its visual representations in tables and figures. Analyze the findings, taking note of key statistics, significant trends, and noteworthy patterns. Engaging in active note-taking and annotation during this pass is highly recommended to solidify your understanding and facilitate later reflection.</p>
              <p>Now, for the purposes of the IRO, it is suggested that only the relevant methodology and results are observed, as there is a limited time frame. Under normal circumstances, the second pass can be made more detailed — but since this is an examination, reduce pass 2 to just the relevant details.</p>

              <p className='italics'>Pass 3: Evaluating the Implications and Future Directions</p>
              <p>The final pass shifts focus to the broader context and potential impact of the research. Immerse yourself in the discussion and conclusion sections. This final pass encourages critical reflection, enabling you to evaluate the study’s contribution to the field and identify potentially fruitful avenues for future research. For the purposes of the IRO, you might not necessarily need this step. However, when conducting systematic literature reviews, this pass is essential in synthesizing the aspects of the paper that appeal most to you and your research project. It is highly recommended that you list down key details and emerging “big ideas” or novel outcomes you can focus on.</p>

              <p className='bold'>Useful Tools</p>
              <p>There are a few tools which can significantly help you during the research and literature review process:</p>
              <ul>
                <li>Paperpile: This powerhouse extension transforms your browser into a research hub. Organize and annotate PDFs directly within your preferred interface, facilitating seamless note-taking and reference management.</li>
                <li>Mendeley: Beyond organization, Mendeley facilitates reference management, generating accurate citations in various academic styles and effortlessly managing your scholarly bibliography. Additionally, Mendeley seamlessly integrates with PDF annotations, streamlining your research workflow.</li>
                <li>Zotero: Mendeley and Zotero both achieve a similar function. However, if your sources are more diverse (i.e. more than just papers), Zotero does a pretty good job.</li>
                <li>Elicit.ai: Elicit.ai extracts key points and summaries from complex research papers, offering rapid insights into their core findings. This allows you to prioritize high-yield resources and optimize your reading time. It also helps you speed up a literature review by shortlisting relevant papers.</li>
              </ul>
              
              <p>There are several tips to remember before reading a research paper. To make your limited time as efficient as possible, read critically and creatively. Reading critically requires the reader to be skeptical. Ask more about what the author is trying to achieve. Are they solving the right question? Are there flaws in their argument? What about limitations and implications?</p>
              <p>Reading creatively involves “harder, more positive” reading. Readers should be asking what can be added to the author's argument, or what can be added to the author's line of thought. As you are reading a research paper, make sure to take note of questions, important details, and related data points. It may also be useful to underline/highlight key terms.</p>
              <p>You should read a research paper multiple times, and starting with a slow, solid read-through is very important. After your first read-through, you should be able to summarize the research paper and provide a list of key points/takeaways that the author is trying to convey. Then, confirm this using a second-read through. Make sure to understand key terms, either by using given definitions or context clues.</p>
              <p>Additionally, after the first read-through, try to add more details to your summary, making it a little more specific. Here is a quick outline of what to do:</p>

              <ol>

                <li>
                  <p>First Pass (Quick Overview):</p>
                  <ul>
                    <li>Title, abstract, introduction.</li>
                    <li>Skim headings, subheadings, discussion/summary.</li>
                    <li>Skip related work initially.</li>
                    <li>Understand the overall structure.</li>
                  </ul>
                </li>

                <li>
                  <p>Second Pass (Detailed Reading):</p>
                  <ul>
                    <li>Read more in-depth, ignoring proofs.</li>
                    <li>Note down key points: main findings, methodology/techniques, and interesting examples/facts.</li>
                    <li>Examine figures, diagrams, and graphs for statistical significance.</li>
                    <li>Make relevant notes in the margin</li>
                    <li>Summarize the paper into 1-2 sentences.</li>
                  </ul>
                </li>

                <li>Third pass (If time): Imagine in your head how you would share this research to others.</li>
              </ol>

              <h5 id='litReview'>How to Conduct a Literature Review:</h5>
              <p>An effective research project rests on a foundation of existing knowledge. Literature reviews are how one establishes the baseline level of knowledge required to start a research project. As the name would suggest, they involve reviewing existing work on the subject that one intends on eventually researching. An effective literature review involves analyzing and synthesizing the various sources. It is a very important step in theses, dissertations, and research papers. Here is a step-by-step guide to conducting a literature review:</p>

              <ul>

                <li>
                  <p>Introduction Paragraph</p>
                  <ul>
                    <li>Your introduction should include your topic and thesis, as well as a brief discussion of your sources.</li>
                  </ul>
                </li>

                <li>
                  <p>Body Paragraph</p>
                  <ul>
                    <li>Your body paragraphs should summarize findings from multiple sources and combine them into coherent sentences. In addition, you should add your thoughts and interpretations, while also including the importance of the finding. Mentioning strengths and weaknesses is also important.</li>
                  </ul>
                </li>

                <li>
                  <p>Conclusion Paragraph</p>
                  <ul>
                    <li>Your conclusion should include an overall summary of your thoughts as well as the connection back to your research question.</li>
                  </ul>
                </li>

              </ul>

              <p>Although this template points out a general outline of how a literature review should be conducted, the order of sources and information in your paragraphs is another important factor that should not go unchecked. Some possible methods are chronological ordering and methodological ordering. In chronological order, the sources go by years, showing the development of the topic over time. In methodological ordering, sources are compared based on different methodologies, which can be very useful in some topics. To help make your literature review coherent and credible, make sure to use multiple sources, as well as establish your line of connection between sources. Make sure to include as many of your own ideas and interpretations as they are the most important part of a literature review.</p>

              <h5 id='practice'>How to Practice:</h5>
              <p>There is a freely available mock exam on the website that includes questions that are similar to those that will be asked in the IRO. If you would like additional practice in reading research papers, we recommend that you look through research papers and use the reading techniques covered in the previous section. The following sites are a good place to start when looking for research papers. Keep in mind that not all papers on these sites have been peer-reviewed yet.</p>
              <ul>
                <li><a href="https://arxiv.org/">https://arxiv.org/</a></li>
                <li><a href="https://ieeexplore.ieee.org/Xplore/home.jsp">https://ieeexplore.ieee.org/Xplore/home.jsp</a></li>
                <li><a href="https://www.ncbi.nlm.nih.gov/pmc/">https://www.ncbi.nlm.nih.gov/pmc/</a></li>
                <li><a href="https://scholar.google.com/">https://scholar.google.com/</a></li>
                <li><a href="https://inspirehep.net/">https://inspirehep.net/</a></li>
              </ul>
              <p>We also suggest that you look for open-access scientific journals in all of the fields that the exam covers. This will also help you get acclimated with the skills required to read and understand research. Not all the papers will make sense, but that is to be expected. The IRO is designed in such a way that students can complete the exam without the advanced scientific knowledge required to fully understand research papers in a particular field.</p>

              <h5 id='extra'>Extra Resources</h5>
              <ul>

                <li>
                  <p>How to read a reserach paper</p>
                  <a href="https://www.eecs.harvard.edu/~michaelm/postscripts/ReadPaper.pdf">https://www.eecs.harvard.edu/~michaelm/postscripts/ReadPaper.pdf</a>
                </li>

                <li>
                  <p>OWL Purdue Guide to Literature Reviews</p>
                  <a href="https://owl.purdue.edu/owl/research_and_citation/conducting_research/writing_a_literature_review.html">https://owl.purdue.edu/owl/research_and_citation/conducting_research/writing_a_literature_review.html</a>
                </li>

                <li>
                  <p>How to read a journal article:</p>
                  <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3687192/pdf/JOMFP-17-65.pdf">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3687192/pdf/JOMFP-17-65.pdf</a>
                </li>

                <li>
                  <p>Videos to help better understand the process of creating research:</p>
                  <a href="https://library.unc.edu/support/tutorials/">https://library.unc.edu/support/tutorials/</a>
                </li>

                <li>
                  <p>A detailed handout explaining literature reviews (University of North Carolina – Chapel Hill)</p>
                  <a href="https://writingcenter.unc.edu/tips-and-tools/literature-reviews/">https://writingcenter.unc.edu/tips-and-tools/literature-reviews/</a>
                </li>

                <li>
                  <p>Concise explanation of literature reviews and video</p>
                  <a href="https://libguides.uwf.edu/litreview">https://libguides.uwf.edu/litreview</a>
                </li>

                <li>
                  <p>Concise guide on writing a literature review (University of Wisconsin – Madison)</p>
                  <a href="https://writing.wisc.edu/handbook/assignments/reviewofliterature/">https://writing.wisc.edu/handbook/assignments/reviewofliterature/</a>
                </li>

              </ul>

            </div>

            {/* <iframe  src="https://medium.com/@internationalresearcholympiad/how-to-read-papers-cdfa85f8541f"  allow="autoplay"></iframe> */}
            {/* <p className="lowertitle">The IRO doesn’t have a specific textbook to read. We do not believe that making students memorize an entire textbook is beneficial. However, there are several ways you can prepare for the IRO. We are testing critical thinking and analytical skills—and the open examination will test your ability to read science literature. In preparation for the IRO open exam, our goal is for students to (1) learn to read papers—a critical skill for scientists, and (2) learn more about subjects they are interested in. <br/><br/>However, there will be some aspect of breadth on the exam — and being able to read a variety of papers i.e. not just math papers but also biology ones, will be helpful. Below are several papers that we recommend you read for now (~3 hours of reading). More resources will be released when registration opens.</p>
            <p className="lowertitle">Try reading some of these papers—don't worry about not understanding some words; focus on the context and the points made instead.</p>

            <Stack direction="row" style={{paddingTop: "10px", overflowX:'auto'}} spacing={1}>
              <a href="https://bmcneurosci.biomedcentral.com/counter/pdf/10.1186/s12868-016-0262-y.pdf" target="_blank"> <Chip icon={<AttachFileIcon />} label="Enzymatic Brain Tissue Dissociation" variant="outlined" /> </a>
              <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9454000/" target="_blank"> <Chip icon={<AttachFileIcon />} label="Biomaterials for Tissue Regeneration" variant="outlined" /> </a>
              <a href="https://arxiv.org/pdf/1310.4546.pdf" target="_blank"> <Chip icon={<AttachFileIcon />} label="Word and Phrase Distributed Representations" variant="outlined" /> </a>
            </Stack> */}
            </div>
          </div>

          <div className="row rowpad1" style={{alignItems: "center", justifyContent: "center"}}>
        
         

        </div>
      </div>
    </div>
  )
}

export default Reviewandvideos
